import React, { useEffect, useRef, useState } from "react";

const easeOut = (x) => Math.pow(x, 0.2);

export default function IncreasingNumber({
  value,
  spline = easeOut,
  duration = 2000,
}) {
  const [display, setDisplay] = useState(0);
  const containerRef = useRef(null);
  const startRef = useRef(null);

  useEffect(() => {
    let req;
    const count = () => {
      const startTime = startRef.current;
      const ellapsed = Date.now() - startTime;

      if (ellapsed >= duration) {
        setDisplay(value);
      } else {
        setDisplay(Math.round(value * spline(ellapsed / duration)));
        req = requestAnimationFrame(count);
      }
    };
    const onObserve = (entries) => {
      entries.forEach((entry) => {
        if (!startRef.current && entry.isIntersecting) {
          startRef.current = Date.now();
          req = requestAnimationFrame(count);
        }
      });
    };
    const observer = new IntersectionObserver(onObserve, {
      threshold: 1,
    });
    observer.observe(containerRef.current);

    return () => {
      cancelAnimationFrame(req);
    };
  }, [value, spline, duration]);

  return <span ref={containerRef}>{display}</span>;
}
