import "./index.scss";
import { PARTICIPANT_ROLES } from "data/statics";
import * as _ from "utils";

import { graphql } from "gatsby";

import React from "react";
import useTranslation from "hooks/useTranslation";

import IndexLayout from "layouts/Index";
import SEO from "components/SEO";
import * as Icons from "components/Icons";
import { WrappingBox } from "components/UI";

import IncreasingNumber from "components/IncreasingNumber";
import Feature from "components/Landing/Feature";
import InfoFeatures from "components/Landing/InfoFeatures";
import GroupFeatures from "components/Landing/GroupFeatures";
import EventOptionFeatures from "components/Landing/EventOptionFeatures";

const IconOne = (props) => <strong {...props}>1</strong>;
const IconTwo = (props) => <strong {...props}>2</strong>;
const IconThree = (props) => <strong {...props}>3</strong>;

export default ({ data }) => {
  const { t } = useTranslation();
  return (
    <IndexLayout>
      <SEO titleTemplate={t`home:seoTitle`} title="unused_title" />
      <header className="index__header relative max-w-4xl px-4 md:px-8 mx-auto flex flex-col justify-center">
        <div className="space-y-8">
          <div className="flex flex-col text-center items-center">
            <h1 className="index__title">
              {t`home:title`}
              <Icons.Pizza className="index__header__float index__header__pizza" />
              <Icons.Cutlery className="index__header__float index__header__cutlery" />
              <Icons.Chef className="index__header__float index__header__chef" />
            </h1>
            <p className="index__tagline text-caption">{t`home:tagline`}</p>

            <p className="text-xs text-caption">
              {t`home:free1`}{" "}
              <a
                className="link--text inline-flex items-baseline space-x-1"
                href="https://apps.apple.com/app/ikeetmee/id1516188174"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons.Apple size={0.75} />
                <span>iPhone</span>
              </a>
              {", "}
              <a
                className="link--text inline-flex items-baseline space-x-1"
                href="https://play.google.com/store/apps/details?id=com.wouterraateland.ikeetmee"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons.Android size={0.75} />
                <span>Android</span>
              </a>
              {", "}
              {t`home:free2`} web.
            </p>
          </div>
          <WrappingBox spacing={8} className="justify-center">
            <a
              className="link link--primary link--sm space-x-2 text-lg font-bold"
              href="https://app.ikeetmee.nl/new-group"
            >
              <span>{t`home:actions.createGroup`}</span>
              <Icons.Arrow strokeWidth={5} />
            </a>
            <a
              className="link link--secondary link--sm space-x-2 text-lg font-bold"
              href={_.getNewEventURL({ role: PARTICIPANT_ROLES.CHEF })}
            >
              <span>{t`home:actions.createList`}</span>
              <Icons.Arrow strokeWidth={5} />
            </a>
          </WrappingBox>
        </div>
      </header>
      <div className="space-y-48">
        <div className="relative max-w-xl p-4 mx-auto text-left">
          <WrappingBox spacing={4} className="items-stretch">
            <div className="flex-grow w-1/3 min-w-40 rounded bg-card shadow p-4">
              <strong className="text-secondary text-3xl flex space-x-2 items-center">
                <Icons.Cutlery size={2} />
                <IncreasingNumber value={data.allList.totalCount} />
              </strong>
              <p>{t`home:listsCreated`}</p>
            </div>
            <div className="flex-grow w-1/3 min-w-40 rounded bg-card shadow p-4">
              <strong className="text-primary text-3xl flex space-x-2 items-center">
                <Icons.Group size={2} />
                <IncreasingNumber value={data.allGroup.totalCount} />
              </strong>
              <p>{t`home:groupsUsing`}</p>
            </div>
          </WrappingBox>
        </div>
        <div className="max-w-4xl p-4 mx-auto space-y-8">
          <h2 className="index__subtitle">
            {t`home:explanation.title1`}
            <br />
            <span className="text-yellow">{t`home:explanation.title2`}</span>
          </h2>
          <WrappingBox spacing={8} className="items-flex-start">
            <Feature
              icon={IconOne}
              title={t`home:explanation.step1.title`}
              description={t`home:explanation.step1.description`}
              className="index__feature--third"
            />
            <Feature
              icon={IconTwo}
              title={
                <>
                  {t`home:explanation.step2.title`}{" "}
                  <span className="inline-block">
                    <span className="index__feature__btn inline-flex items-center bg-success font-bold rounded-full">
                      <Icons.Check />
                      <span>{t`common:yes`}</span>
                    </span>{" "}
                    {t`common:or`}{" "}
                    <span className="index__feature__btn inline-flex items-center bg-error font-bold rounded-full">
                      <Icons.Cross />
                      <span>{t`common:no`}</span>
                    </span>
                  </span>
                </>
              }
              description={t`home:explanation.step2.description`}
              className="index__feature--third"
            />
            <Feature
              icon={IconThree}
              title={t`home:explanation.step3.title`}
              description={t`home:explanation.step3.description`}
              className="index__feature--third"
            />
          </WrappingBox>
        </div>
        <InfoFeatures />
        <GroupFeatures />
        <EventOptionFeatures />
        <div className="bg-card border-0 border-t border-b border-solid border-accent">
          <div className="max-w-4xl px-4 py-16 mx-auto space-y-8 flex flex-col">
            <h2 className="index__subtitle whitespace-pre">{t`home:titleRepeat`}</h2>
            <WrappingBox spacing={8}>
              <a
                className="link link--primary link--sm space-x-2 text-lg font-bold"
                href="https://app.ikeetmee.nl/new-group"
              >
                <span>{t`home:actions.createGroup`}</span>
                <Icons.Arrow strokeWidth={5} />
              </a>
              <a
                className="link link--secondary link--sm space-x-2 text-lg font-bold"
                href={_.getNewEventURL({ role: PARTICIPANT_ROLES.CHEF })}
              >
                <span>{t`home:actions.createList`}</span>
                <Icons.Arrow strokeWidth={5} />
              </a>
            </WrappingBox>
          </div>
        </div>
        <div className="max-w-4xl px-4 pb-16 mx-auto space-y-8">
          <h2 className="index__subtitle">{t`home:social.title`}</h2>
          <div className="flex space-x-8">
            <a
              className="flex flex-col items-flex-start"
              href="mailto:wouterraateland@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons.Email size={4} className="link--text" />
              <span className="text-caption">{t`home:social.sendEmail`}</span>
            </a>
            <a
              className="flex flex-col items-flex-start"
              href="https://twitter.com/wouterraateland"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons.Twitter size={4} className="link--twitter" />
              <span className="text-caption">{t`home:social.tweet`}</span>
            </a>
          </div>
        </div>
      </div>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query {
    allGroup {
      totalCount
    }
    allList {
      totalCount
    }
  }
`;
