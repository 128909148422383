import cx from "classnames";

import React, { useEffect, useState } from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import Feature from "components/Landing/Feature";
import GroupIllustration from "./Illustration";

const Euro = ({ size, className, ...props }) => (
  <span
    className={cx("font-bold", className)}
    style={{ fontSize: `${size / 4}rem` }}
    {...props}
  >
    €
  </span>
);

export default function GroupFeatures() {
  const { t } = useTranslation();
  const [slide, setSlide] = useState(1);

  useEffect(() => {
    const t = setTimeout(() => setSlide((slide) => (slide + 1) % 3), 5000);
    return () => clearTimeout(t);
  }, [slide]);

  return (
    <div className="max-w-4xl p-4 mx-auto relative index__feature-wrapper--group flex flex-col">
      <div className="ml-auto space-y-8">
        <h2 className="index__subtitle relative z-0">
          {t`home:groups.title`}
          <br />
          <span className="text-primary">{t`home:groups.title2`}</span>
        </h2>
        <Feature
          icon={Icons.Repeat}
          color="primary"
          title={t`home:groups.recurring.title`}
          description={t`home:groups.recurring.description`}
          className={cx("clickable transition duration-200", {
            "clickable--clicked": slide === 0,
          })}
          onClick={() => setSlide(0)}
        />
        <Feature
          icon={Icons.Poll}
          color="secondary"
          title={t`home:groups.statistics.title`}
          description={t`home:groups.statistics.description`}
          className={cx("clickable transition duration-200", {
            "clickable--clicked": slide === 1,
          })}
          onClick={() => setSlide(1)}
        />
        <Feature
          icon={Euro}
          color="success"
          title={t`home:groups.expenses.title`}
          description={t`home:groups.expenses.description`}
          className={cx("clickable transition duration-200", {
            "clickable--clicked": slide === 2,
          })}
          onClick={() => setSlide(2)}
        />
        <a
          className="flex items-center link--primary link--sm space-x-2 index__feature font-bold"
          href="https://app.ikeetmee.nl/new-group"
        >
          <span>{t`home:actions.createGroup`}</span>
          <Icons.Arrow strokeWidth={5} />
        </a>
      </div>
      <GroupIllustration slide={slide} />
    </div>
  );
}
