import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import Illustration from "./Illustration";
import MonetaryLiteral from "components/MonetaryLiteral";

import chris from "images/chris.jpg";
import leah from "images/leah.jpg";
import leo from "images/leo.jpg";
import lida from "images/lida.jpg";
import renee from "images/renee.jpg";
import steve from "images/steve.jpg";

const Participants = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-space-between p-2 space-x-2 rounded-md shadow-xs bg-card">
      <div className="flex-grow min-w-0 space-y-2">
        <p className="text-sm">{t`home:infoIllustration.participants`}</p>
        <div className="collapse flex items-center space-x-2 overflow-hidden fade-r">
          <div className="relative">
            <img className="block w-8 h-8 rounded-full" src={leo} alt="Leo" />
            <div className="info-illustration__check absolute right-0 bottom-0 flex items-center justify-center w-3 h-3 rounded-full bg-success">
              <Icons.Check size={0.5} />
            </div>
          </div>
          <div className="relative">
            <img className="block w-8 h-8 rounded-full" src={leah} alt="Leah" />
            <div className="info-illustration__check absolute right-0 bottom-0 flex items-center justify-center w-3 h-3 rounded-full bg-success">
              <Icons.Check size={0.5} />
            </div>
          </div>
          <img className="block w-8 h-8 rounded-full" src={chris} alt="Chris" />
          <img className="block w-8 h-8 rounded-full" src={renee} alt="Renee" />
          <img className="block w-8 h-8 rounded-full" src={lida} alt="Lida" />
          <img className="block w-8 h-8 rounded-full" src={steve} alt="Steve" />
        </div>
        <p className="flex flex-wrap wrapping-box--2 collapse text-xs">
          <span className="rounded-full pl-1 pr-2 bg-glow text-text">
            <span
              role="img"
              className="text-text"
              aria-label={t`home:infoIllustration.vegetarian`}
            >
              🥦
            </span>{" "}
            {t`home:infoIllustration.vegetarian`} x1
          </span>
          <span className="rounded-full pl-1 pr-2 bg-glow text-text">
            <span
              role="img"
              className="text-text"
              aria-label={t`home:infoIllustration.proteinRich`}
            >
              🥚
            </span>{" "}
            {t`home:infoIllustration.proteinRich`} x1
          </span>
        </p>
      </div>
      <Icons.Caret direction="right" className="text-caption" />
    </div>
  );
};

const Expenses = () => {
  const { t } = useTranslation();

  return (
    <div class="p-2 space-y-2 bg-card rounded-md shadow-sm">
      <p class="font-bold">{t`home:infoIllustration.expensesToSettle`}</p>
      <div class="space-y-4">
        <div class="flex items-center justify-space-between space-x-2">
          <div class="flex-grow flex items-center justify-space-between">
            <div>
              <p>{t`home:infoIllustration.groceries`}</p>
              <div class="flex space-x-2">
                <img className="w-4 h-4 rounded-full" src={leo} alt="Leo" />
                <p class="text-xs text-caption">Leo</p>
              </div>
            </div>
            <MonetaryLiteral cents={1200} />
          </div>
          <Icons.Caret direction="right" className="text-caption" />
        </div>
      </div>
    </div>
  );
};

const Comments = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center space-x-4 relative">
      <img className="block w-10 h-10 rounded-full" src={leah} alt="Amber" />
      <div className="bg-card rounded-md rounded-bl-none shadow-md px-4 py-2">
        <p>{t`home:infoIllustration.comment`}</p>
      </div>
    </div>
  );
};

export default function InfoIllustration() {
  const { t } = useTranslation();
  return (
    <Illustration>
      <div className="space-y-4">
        <div className="flex items-center space-x-4">
          <img className="block w-8 h-8 rounded-full" src={leo} alt="Leo" />
          <p className="text-xl">{t`home:infoIllustration.iCook`}</p>
        </div>
        <div className="flex items-center space-x-4">
          <Icons.Clock size={1.5} className="illustration__icon" />
          <p>{t`home:infoIllustration.dinnerTime`}</p>
        </div>
        <div className="flex items-center space-x-4">
          <Icons.Lock size={1.5} isLocked className="illustration__icon" />
          <div>
            <p>{t`home:infoIllustration.closed`}</p>
            <p className="text-xs text-caption">{t`home:infoIllustration.deadline`}</p>
          </div>
        </div>
        <div className="rounded-md bg-card p-2 shadow-sm">
          <h2>{t`home:infoIllustration.dish`}</h2>
        </div>
        <Participants />
        <Expenses />
        <Comments />
      </div>
    </Illustration>
  );
}
