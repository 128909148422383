import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import MonetaryLiteral from "components/MonetaryLiteral";

import chris from "images/chris.jpg";
import leo from "images/leo.jpg";
import renee from "images/renee.jpg";

const expenses = [
  {
    creditor: {
      name: "Chris",
      avatar: chris,
    },
    cents: 1780,
    description: "groceries",
    date: new Date("2020-10-23"),
    event: "luxuryDinner",
  },
  {
    creditor: {
      name: "Renee",
      avatar: renee,
    },
    cents: 1250,
    description: "beers",
    date: new Date("2020-10-23"),
    event: "luxuryDinner",
  },
  {
    creditor: {
      name: "Leo",
      avatar: leo,
    },
    cents: 2245,
    description: "movieTickets",
    date: new Date("2020-10-22"),
  },
  {
    creditor: {
      name: "Renee",
      avatar: renee,
    },
    cents: 500,
    description: "snacks",
    date: new Date("2020-10-22"),
  },
];

export default function ExpensesSlide() {
  const { t, moment } = useTranslation();
  return (
    <div className="space-y-4 w-64">
      <button className="w-full btn btn--sm btn--primary font-bold">
        {t`home:groupIllustration.settleExpenses`}
      </button>
      {expenses.map((expense, i) => (
        <div
          key={i}
          className="flex items-center justify-space-between space-x-2"
        >
          <div className="flex-grow flex items-center justify-space-between">
            <div>
              <p>
                {t(
                  `home:groupIllustration.expenseDescriptions.${expense.description}`
                )}
              </p>
              <div className="flex space-x-2">
                <img
                  className="w-4 h-4 rounded-full"
                  src={expense.creditor.avatar}
                  alt={expense.creditor.name}
                />
                <p className="text-xs text-caption">
                  {expense.creditor.name} •{" "}
                  {expense.event
                    ? t(`home:groupIllustration.${expense.event}`)
                    : moment(expense.date).format("ll")}
                </p>
              </div>
            </div>
            <MonetaryLiteral cents={expense.cents} />
          </div>
          <Icons.Caret direction="right" className="text-caption" />
        </div>
      ))}
    </div>
  );
}
