import "./Illustration.scss";
import cx from "classnames";
import React, { useEffect, useRef } from "react";

export default function Illustration({ className, ...props }) {
  const containerRef = useRef(null);
  const innerRef = useRef(null);

  useEffect(() => {
    innerRef.current.style.transitionDuration = "0.4s";
    innerRef.current.style.transitionDelay = "0.2s";
    const onObserve = (entries) => {
      entries.forEach((entry) => {
        if (innerRef.current && entry.isIntersecting) {
          innerRef.current.classList.toggle("enter-done", true);
        }
      });
    };
    const observer = new IntersectionObserver(onObserve, {
      threshold: 0.5,
    });
    observer.observe(containerRef.current);
  }, []);

  return (
    <div ref={containerRef} className={cx("illustration", className)}>
      <div ref={innerRef} className="shift-in" {...props} />
    </div>
  );
}
