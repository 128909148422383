import React from "react";

import Navigation from "components/Navigation";
import Footer from "components/Footer";

export default function IndexLayout({ children, ...props }) {
  return (
    <>
      <Navigation {...props} />
      {children}
      <Footer />
    </>
  );
}
