export const SUPPORTED_LANGUAGES = ["en", "nl"];
export const SHARE_URL = "https://ikeetmee.nl";

export const COURSE_FORMATS = {
  SINGLE_OPTION: "singleOption",
  MULTIPLE_OPTIONS: "multipleOptions",
  POLL: "poll",
  ORDER: "order",
};

export const PARTICIPANT_ROLES = {
  EATER: "eater",
  NON_EATER: "non-eater",
  CHEF: "chef",
  CREATOR: "creator",
};

export const GROUP_ROLES = {
  GUEST: "guest",
  MEMBER: "member",
  ADMIN: "admin",
};

export const GROUP_TYPES = {
  PRIVATE: "private",
  PUBLIC: "public",
};

export const COMMON_PREFERENCES = [
  { emoji: "🥚", name: "highProtein" },
  { emoji: "🥖", name: "lowCarb" },
  { emoji: "🧂", name: "lowSodium" },
  { emoji: "🐟", name: "fishFree" },
  { emoji: "🥦", name: "vegetarian" },
  { emoji: "🌱", name: "vegan" },
  { emoji: "🍖", name: "paleo" },
  { emoji: "✡️", name: "kosher" },
  { emoji: "☪️", name: "halal" },
  { emoji: "🧁", name: "sugarFree" },
  { emoji: "🌾", name: "glutenFree" },
  { emoji: "🥛", name: "lactoseFree" },
  { emoji: "🥜", name: "nutFree" },
];

export const PERIODS = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
};

export const DATE_TYPES = {
  DAY: "day",
  NTH: "nth",
};

export default {
  SUPPORTED_LANGUAGES,
  SHARE_URL,
  COURSE_FORMATS,
  PARTICIPANT_ROLES,
  COMMON_PREFERENCES,
  GROUP_ROLES,
  GROUP_TYPES,
  PERIODS,
  DATE_TYPES,
};
