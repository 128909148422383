import React from "react";
import useTranslation from "hooks/useTranslation";

import { Tag } from "components/UI";
import Illustration from "./Illustration";

import applePie from "images/apple-pie.jpg";
import vegaBurger from "images/vega-burger.jpg";
import angusBurger from "images/angus-burger.jpg";

export default function OptionIllustration() {
  const { t } = useTranslation();
  return (
    <Illustration>
      <div className="space-y-4">
        <div className="relative">
          <div className="absolute illustration__tags flex space-x-2">
            <Tag className="text-xs" color="primary">
              {t`home:optionsIllustration.mainDish`}
            </Tag>
            <Tag className="text-xs">{t`home:optionsIllustration.chooseFrom`}</Tag>
          </div>
          <div className="rounded-md bg-card p-2 pt-4 space-y-4">
            <div className="flex items-center space-x-4">
              <img
                className="block w-12 h-12 rounded-md"
                src={angusBurger}
                alt={t`home:optionsIllustration.burger`}
              />
              <div className="space-y-1 flex-grow">
                <strong>{t`home:optionsIllustration.burger`}</strong>
                <div className="flex space-x-2">
                  <Tag className="text-xs">€3,50</Tag>
                </div>
                <div className="flex items-baseline space-x-1">
                  <span className="text-caption">5x</span>
                  <div className="rounded w-full h-1 bg-glow">
                    <div
                      className="rounded h-full bg-primary"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <img
                className="block w-12 h-12 rounded-md"
                src={vegaBurger}
                alt={t`home:optionsIllustration.vegaBurger`}
              />
              <div className="space-y-1 flex-grow">
                <strong>
                  {t`home:optionsIllustration.vegaBurger`}{" "}
                  <span role="img" aria-label="vegetarisch">
                    🌱
                  </span>
                </strong>
                <div className="flex space-x-2">
                  <Tag className="text-xs">€3,25</Tag>
                </div>
                <div className="flex items-baseline space-x-1">
                  <span className="text-caption">2x</span>
                  <div className="rounded w-full h-1 bg-glow">
                    <div
                      className="rounded h-full bg-primary"
                      style={{ width: "40%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="absolute illustration__tags flex space-x-2">
            <Tag className="text-xs" color="primary">
              {t`home:optionsIllustration.dessert`}
            </Tag>
          </div>
          <div className="rounded-md bg-card p-2 space-y-4">
            <div className="flex space-x-4">
              <img
                className="block w-16 h-16 mt--4 rounded-md"
                src={applePie}
                alt={t`home:optionsIllustration.applePie`}
              />
              <div className="space-y-1 flex-grow">
                <strong>{t`home:optionsIllustration.applePie`}</strong>
                <div className="flex space-x-2">
                  <Tag className="text-xs">{t`home:optionsIllustration.recipeFrom`}</Tag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Illustration>
  );
}
