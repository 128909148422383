import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Tag } from "components/UI";

import chris from "images/chris.jpg";
import leah from "images/leah.jpg";
import renee from "images/renee.jpg";

export default function RecurringSlide() {
  const { t } = useTranslation();
  return (
    <div className="space-y-4 w-64">
      <div className="flex space-x-4">
        <div className="text-center">
          <p className="text-secondary">{t`home:groupIllustration.mondayShort`}</p>
          <p className="text-lg bg-secondary rounded-full w-8 h-8 flex items-center justify-center">
            03
          </p>
        </div>
        <div className="space-y-4 w-full">
          <div className="relative">
            <div className="absolute illustration__tags">
              <Tag
                color="primary"
                className="flex items-center space-x-1 text-xs"
              >
                <Icons.Repeat size={0.75} />
                <span>{t`home:groupIllustration.daily`}</span>
              </Tag>
            </div>
            <div className="rounded-md px-4 py-2 border bg-card shadow-sm space-y-2">
              <div>
                <p>Leo {t`home:groupIllustration.cooks`}</p>
                <h3>Lasagne al forno</h3>
              </div>
              <div className="flex space-x-1 items-center">
                <Icons.Person size={0.75} />
                <span className="text-caption text-xs">3</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="text-center">
          <p>{t`home:groupIllustration.tuesdayShort`}</p>
          <p className="text-lg w-8">04</p>
        </div>
        <div className="space-y-4 w-full min-w-0">
          <div className="relative">
            <div className="absolute illustration__tags">
              <Tag
                color="primary"
                className="flex items-center space-x-1 text-xs"
              >
                <Icons.Repeat size={0.75} />
                <span>{t`home:groupIllustration.daily`}</span>
              </Tag>
            </div>
            <div className="rounded-md px-4 py-2 space-y-2 border border-dashed border-accent">
              <p>{t`home:groupIllustration.nobodyCooksYet`}</p>
              <div className="flex space-x-1 items-center">
                <Icons.Person size={0.75} />
                <span className="text-caption text-xs">0</span>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="absolute illustration__tags">
              <Tag
                color="primary"
                className="flex items-center space-x-1 text-xs"
              >
                <Icons.Repeat size={0.75} />
                <span>{t`home:groupIllustration.weeklyOnTuesday`}</span>
              </Tag>
            </div>
            <div className="rounded-md p-2 pr-4 border bg-card shadow-sm flex justify-space-between">
              <div className="flex space-x-2 min-w-0">
                <img className="w-6 h-6 rounded-full" src={chris} alt="Chris" />
                <p className="truncate">{t`home:groupIllustration.kitchenClean`}</p>
              </div>
              <div className="flex space-x-1 items-center">
                <Icons.Check />
                <span>0/8</span>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="absolute illustration__tags">
              <Tag
                color="primary"
                className="flex items-center space-x-1 text-xs"
              >
                <Icons.Repeat size={0.75} />
                <span>{t`home:groupIllustration.weeklyOnTuesday`}</span>
              </Tag>
            </div>
            <div className="rounded-md p-2 pr-4 border bg-card shadow-sm flex justify-space-between opacity-75">
              <div className="flex space-x-2">
                <img className="w-6 h-6 rounded-full" src={leah} alt="Leah" />
                <p>{t`home:groupIllustration.trashDone`}</p>
              </div>
              <div className="flex space-x-1 items-center">
                <Icons.Check />
                <span>0/2</span>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="absolute illustration__tags">
              <Tag
                color="primary"
                className="flex items-center space-x-1 text-xs"
              >
                <Icons.Repeat size={0.75} />
                <span>{t`home:groupIllustration.weeklyOnTuesday`}</span>
              </Tag>
            </div>
            <div className="rounded-md p-2 pr-4 border bg-card shadow-sm flex justify-space-between opacity-50">
              <div className="flex space-x-2">
                <img className="w-6 h-6 rounded-full" src={renee} alt="Renee" />
                <p>{t`home:groupIllustration.toiletsClean`}</p>
              </div>
              <div className="flex space-x-1 items-center">
                <Icons.Check />
                <span>0/5</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
