import cx from "classnames";
import React from "react";

export default function Feature({
  icon: Icon,
  color = "card",
  title,
  description,
  className,
  ...props
}) {
  return (
    <div
      className={cx(
        "index__feature flex space-x-4 items-flex-start",
        className
      )}
      {...props}
    >
      <div className={`p-1 rounded-xl bg-${color}`}>
        <Icon className="index__feature__icon" />
      </div>
      <div>
        <p className="index__feature__title">{title}</p>
        {description && (
          <p className="index__feature__description text-caption">
            {description}
          </p>
        )}
      </div>
    </div>
  );
}
