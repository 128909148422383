import * as _ from "utils";
import { PARTICIPANT_ROLES } from "data/statics";

import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { WrappingBox } from "components/UI";
import InfoIllustration from "./InfoIllustration";

export default function InfoFeatures() {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl p-4 mx-auto space-y-8 relative">
      <h2 className="index__subtitle">
        {t`home:info.title1`}
        <br />
        <span className="text-green">{t`home:info.title2`}</span>
      </h2>
      <WrappingBox
        spacing={8}
        className="index__feature-wrapper--illustrated items-flex-start"
      >
        <p className="index__feature flex items-center space-x-4">
          <Icons.Clock className="index__feature__icon" />
          <span>{t`home:info.dinnerTime`}</span>
        </p>
        <p className="index__feature flex items-center space-x-4">
          <Icons.Lock className="index__feature__icon" />
          <span>{t`home:info.deadline`}</span>
        </p>
        <p className="index__feature flex items-center space-x-4">
          <Icons.Location className="index__feature__icon" />
          <span>{t`home:info.location`}</span>
        </p>
        <p className="index__feature flex items-center space-x-4">
          <Icons.Cutlery className="index__feature__icon" />
          <span>{t`home:info.dish`}</span>
        </p>
        <p className="index__feature flex items-center space-x-4">
          <Icons.Person className="index__feature__icon" />
          <span>{t`home:info.participants`}</span>
        </p>
        <p className="index__feature flex items-center space-x-4">
          <span className="index__feature__icon font-bold">€</span>
          <span>{t`home:info.expenses`}</span>
        </p>
        <p className="index__feature flex items-center space-x-4">
          <Icons.Comment className="index__feature__icon" />
          <span>{t`home:info.comments`}</span>
        </p>
      </WrappingBox>
      <a
        className="flex items-center link--green link--sm space-x-2 index__feature font-bold"
        href={_.getNewEventURL({ role: PARTICIPANT_ROLES.CHEF })}
      >
        <span>{t`home:actions.createList`}</span>
        <Icons.Arrow strokeWidth={5} />
      </a>
      <InfoIllustration />
    </div>
  );
}
