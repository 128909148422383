import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { WrappingBox } from "components/UI";
import Feature from "./Feature";
import OptionIllustration from "./OptionIllustration";

export default function EventOptionFeatures() {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl p-4 mx-auto space-y-8 relative">
      <h2 className="index__subtitle">{t`home:features.title`}</h2>
      <WrappingBox
        spacing={8}
        className="index__feature-wrapper--illustrated items-flex-start"
      >
        <Feature
          icon={Icons.Plus}
          color="yellow"
          title={t`home:features.poll`}
          className="index__feature--half"
        />
        <Feature
          icon={Icons.Chef}
          color="primary"
          title={t`home:features.multipleCourses`}
          className="index__feature--half"
        />
        <Feature
          icon={Icons.Pizza}
          color="secondary"
          title={t`home:features.takeOrders`}
          className="index__feature--half"
        />
        <Feature
          icon={Icons.Diet}
          color="success"
          title={t`home:features.dietaryPreferences`}
          className="index__feature--half"
        />
      </WrappingBox>
      <OptionIllustration />
    </div>
  );
}
