import cx from "classnames";

import React from "react";

export default function MonetaryLiteral({ cents, allwaysShowSign, className }) {
  const [euros, remainder] = (cents / 100).toFixed(2).split(/\.|,/g);
  const chars =
    Math.floor(Math.log10(Math.abs(euros))) +
    ((allwaysShowSign && cents > 0) || cents < 0 ? 1 : 0);
  const size = chars >= 3 ? 1 : 1.5;

  return (
    <span
      className={cx(
        "inline-block min-w-16 whitespace-no-wrap text-xl text-right leading-none",
        className
      )}
      style={{ fontSize: `${size}rem` }}
    >
      <span>
        {allwaysShowSign && cents > 0 ? "+" : ""}
        {euros}
      </span>
      <span
        className="opacity-75"
        style={{ fontSize: "0.66em", verticalAlign: "0.4em" }}
      >
        {remainder}
      </span>
    </span>
  );
}
