import * as _ from "utils";
import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";

import chris from "images/chris.jpg";
import leah from "images/leah.jpg";
import leo from "images/leo.jpg";
import lida from "images/lida.jpg";
import renee from "images/renee.jpg";
import steve from "images/steve.jpg";

const members = [
  { name: "Chris", avatar: chris, value: 7 },
  { name: "Leah", avatar: leah, value: 5 },
  { name: "Renee", avatar: renee, value: 2 },
  { name: "Steve", avatar: steve, value: 0 },
  { name: "Lida", avatar: lida, value: -4 },
  { name: "Leo", avatar: leo, value: -10 },
];

export default function StatisticsSlide() {
  const { t } = useTranslation();
  return (
    <div className="space-y-4 w-64">
      <div className="flex items-center justify-space-between px-3 py-2 border border-solid border-accent rounded-lg bg-card">
        <span className="leading-none">{t`home:groupIllustration.cookBalance`}</span>
        <Icons.Caret direction="down" />
      </div>
      {members.map((member) => (
        <div
          key={member.name}
          className="flex items-center justify-space-between"
        >
          <div className="flex items-center space-x-4">
            <img
              className="w-10 h-10 rounded-full"
              src={member.avatar}
              alt={member.name}
            />
            <p>{member.name}</p>
          </div>
          <strong
            className={`text-${
              member.value > 0
                ? "success"
                : member.value < 0
                ? "error"
                : "caption"
            }`}
          >
            {_.signed(member.value).substr(0, 4)}
          </strong>
        </div>
      ))}
    </div>
  );
}
