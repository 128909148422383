import React from "react";
import useTranslation from "hooks/useTranslation";

import Illustration from "components/Landing/Illustration";
import RecurrenceSlide from "./RecurrenceSlide";
import StatisticsSlide from "./StatisticsSlide";
import ExpensesSlide from "./ExpensesSlide";

import house from "images/house.jpg";
import chris from "images/chris.jpg";
import leo from "images/leo.jpg";
import leah from "images/leah.jpg";
import steve from "images/steve.jpg";
import lida from "images/lida.jpg";

export default function GroupIllustration({ slide }) {
  const { t } = useTranslation();
  return (
    <Illustration className="illustration--group overflow-hidden">
      <div className="space-y-4">
        <div>
          <div className="relative w-24 h-24 mx-auto">
            <img
              className="w-24 h-24 rounded-full"
              src={house}
              alt={t`home:groupIllustration.groupName`}
            />
            <img
              className="absolute w-10 h-10 rounded-full group-graphic__member--side group-graphic__member--0"
              src={steve}
              alt="Steve"
            />
            <img
              className="absolute w-10 h-10 rounded-full group-graphic__member--side group-graphic__member--1"
              src={leah}
              alt="Leah"
            />
            <img
              className="absolute w-10 h-10 rounded-full group-graphic__member--side group-graphic__member--2"
              src={lida}
              alt="Lida"
            />
            <img
              className="absolute w-10 h-10 rounded-full group-graphic__member--side group-graphic__member--3"
              src={chris}
              alt="Chris"
            />
            <img
              className="absolute w-10 h-10 rounded-full group-graphic__member--side group-graphic__member--4"
              src={leo}
              alt="Leo"
            />
            <div className="absolute w-10 h-10 rounded-full group-graphic__member--side group-graphic__member--5 bg-purple flex items-center justify-center text-xl">
              P
            </div>
          </div>
          <p className="text-center">{t`home:groupIllustration.groupName`}</p>
        </div>
        <div
          style={{
            width: "50rem",
            transform: `translate(${-17 * slide}rem, 0)`,
          }}
          className="flex items-flex-start space-x-4 transition-transform duration-500 ease-in-out"
        >
          <RecurrenceSlide />
          <StatisticsSlide />
          <ExpensesSlide />
        </div>
      </div>
    </Illustration>
  );
}
